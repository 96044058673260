<template>
  <div class="home">
    <img alt="COSENM" src="../assets/CSNM.png" style="max-width: 30%;">
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
}
</script>


<style>
  .home {
      padding-top: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
  }
</style>